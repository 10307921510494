<template>
  <div class="paginationBox pageSection clearFix right">
    <div
      class="inputPageModule left clearFix"
      v-if="inputPageModule">
      <input
        type="text"
        v-model="initCurrentPage"
        @keyup="checkNumber"
        @keyup.enter="jumpTo('0')"
        @blur="inserDefaultVal"
        class="left currentPage">
      <div
        class="left pageIcon gotoPageBtn"
        :class="{notAllowed: userJump}"
        @click="jumpTo('0')"
        :title="$t('lang.i18n_jump')"></div>
    </div>
    <div
      class="left pageIcon firstIcon"
      :class="{notAllowed: userChangePagePre}"
      :title="$t('lang.i18n_first')"
      @click="jumpTo('1')"></div>
    <div
      class="left pageIcon previousIcon"
      :class="{notAllowed: userChangePagePre}"
      :title="$t('lang.i18n_pre')"
      @click="jumpTo('2')"></div>
    <div class="left pageNum">{{pageNum}}</div>
    <div
      class="left pageIcon nextIcon"
      :class="{notAllowed: userChangePageNext}"
      :title="$t('lang.i18n_next')"
      @click="jumpTo('3')"></div>
    <div
      class="left pageIcon lastIcon"
      :class="{notAllowed: userChangePageNext}"
      :title="$t('lang.i18n_last')"
      @click="jumpTo('4')"></div>
    <el-dropdown
      class="page_dropdown"
      v-if="pageDropdownFlag"
      split-button
      type="primary"
      @command="handleCommand">

      <span class="el-dropdown-link">
        {{initCurrentSize}}
      </span>
      <el-dropdown-menu
        slot="dropdown"
        class="page_dropdown_menu">
        <el-dropdown-item
          v-for="item in pageSizes"
          :key="item"
          :command="item">
          {{item}}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div
      class="left total"
      v-if="totalFlag">{{$t('lang.i18n_total')}}
      <span class="totalNum">{{total}}</span>{{$t('lang.i18n_severalData')}}</div>
  </div>
</template>

<script>
import { myReceiversAnalyze } from '@/assets/js/googleAnalyze/myReceiver.js'
export default {
  props: {
    currentPage: Number, // 当前第几页
    pageSize: Number, // 每页显示多少条
    pageSizes: { // 下拉框可选数据
      type: Array,
      default: () => {
        return [10, 20, 50, 80]
      }
    },
    layout: { // 控制分页功能
      default: 'total, prev, pageF, next, pageL ,sizes, nowSize, jumper'
    },
    total: Number // 总数据
  },
  data () {
    return {
      inputPageModule: true,
      pageDropdownFlag: true,
      totalFlag: true,
      initCurrentPage: 1, // 用户操作后的新值与currentPage做对比
      initCurrentSize: 10, // 用户操作后的新值与currentSize做对比
      userChangePagePre: true, // 上一页及跳转到第一页是否可使用
      userChangePageNext: false, // 下一页及跳转到最后一页是否可使用
      userChangePageSize: true, // 每页显示数据是否有修改
      userJump: false, // 跳转是否可使用
      lastFlag: false // 是否为最后一页
    }
  },
  mounted () {
    this.inputPageModule = this.layout.indexOf('jumper') > -1
    this.pageDropdownFlag = this.layout.indexOf('sizes') > -1
    this.totalFlag = this.layout.indexOf('total') > -1
    this.initCurrentPage = this.currentPage
    this.initCurrentSize = this.pageSize
    this.initPage()
  },
  computed: {
    pageNum: {
      get: function () {
        return this.currentPage + '/' + Math.ceil(this.total / this.pageSize)
      },
      set: function (v) {
        const MaxPage = Math.ceil(this.total / this.pageSize)
        if (this.lastFlag) {
          this.pageNum = MaxPage + '/' + MaxPage
        } else {
          this.pageNum = this.currentPage + '/' + MaxPage
        }
      }
    }
  },
  watch: {
    total: {
      handler (val) {
        this.initPage()
      }
    },
    currentPage: {
      handler (val) {
        this.initCurrentPage = val
        this.initPage()
      }
    },
    pageSize: {
      handler (val) {
        this.initPage()
      }
    },
    initCurrentPage: {
      handler (val) {
        this.userJump = this.initCurrentPage == ''
      }
    }
  },
  methods: {
    // 组件初始化
    initPage () {
      const maxSize = Math.ceil(this.total / this.pageSize)
      if (maxSize == 1) {
        this.userChangePageNext = true
        this.userChangePagePre = true
        this.userJump = true
      } else if (maxSize < 1) {
        this.userChangePageNext = true
        this.userChangePagePre = true
        this.userJump = true
      } else if (this.currentPage == 1 && maxSize > 1) {
        this.userChangePageNext = false
        this.userChangePagePre = true
        this.userJump = false
      } else if (this.currentPage == maxSize && maxSize != 1) {
        this.userChangePageNext = true
        this.userChangePagePre = false
        this.userJump = true
      } else {
        this.userChangePageNext = false
        this.userChangePagePre = false
        this.userJump = false
      }
    },
    // 控制跳转页的输入
    checkNumber () {
      const maxSize = Math.ceil(this.total / this.pageSize)
      if (this.initCurrentPage > maxSize) {
        this.initCurrentPage = maxSize + ''
        return
      }
      const str = this.initCurrentPage.toString()
      this.initCurrentPage = str.replace(/[^\d]|(^0+)/g, '') // 控制只能输入正整数
    },
    // 点击下拉框
    handleCommand (command) {
      if (this.pageSize == command) {
        this.userChangePageSize = false
        return
      }
      const MaxPage = Math.ceil(this.total / command)
      this.userJump = MaxPage == 1
      this.userChangePagePre = MaxPage == 1
      this.userChangePageNext = MaxPage == 1
      this.userChangePageSize = true
      this.initCurrentPage = this.currentPage > MaxPage ? MaxPage : this.currentPage
      this.lastFlag = this.currentPage > MaxPage
      this.userChangePagePre = this.initCurrentPage == 1
      this.userChangePageNext = this.currentPage >= MaxPage
      this.initCurrentSize = command
      this.emitChange()
    },
    // 跳转
    jumpTo (num) { // 0 跳转到任意一页；1 跳转到首页； 2 上一页； 3 下一页； 4 跳转到最后一页
      let flag = true
      const maxSize = Math.ceil(this.total / this.pageSize)
      if (this.initCurrentPage < 1 || this.initCurrentPage > maxSize) {
        return
      }
      this.userChangePageSize = false
      this.userChangePagePre = maxSize == 1
      this.userChangePageNext = maxSize == 1
      this.userJump = maxSize == 1 // userJump/userChangePagePre/userChangePageNext为true表示不可用
      if (maxSize == 1) return
      switch (num) {
        case '0':
          if (this.userJump) return
          if (this.initCurrentPage == 1 && maxSize != 1) {
            this.userChangePagePre = true
            this.userChangePageNext = false
          }
          if (this.initCurrentPage == maxSize && maxSize != 1) {
            this.userChangePagePre = false
            this.userChangePageNext = true
          }
          break
        case '1':
          if (this.userChangePagePre) return
          this.initCurrentPage = 1
          this.userChangePagePre = true
          this.userChangePageNext = false
          break
        case '2':
          if (this.userChangePagePre) return
          this.userChangePagePre = this.currentPage - 1 <= 1
          myReceiversAnalyze('myReceiversNavPagePrevious', this.$route.path) // 谷歌统计 上一页
          this.userChangePageNext = false
          if (this.currentPage - 1 < 1) {
            flag = false
          } else { this.initCurrentPage = this.currentPage - 1 }
          break
        case '3':
          if (this.userChangePageNext) {
            return
          }
          this.userChangePageNext = this.currentPage + 1 >= maxSize
          myReceiversAnalyze('myReceiversNavPageNext', this.$route.path) // 谷歌统计 上一页
          this.userChangePagePre = false
          if (this.currentPage + 1 > maxSize) {
            flag = false
            this.lastFlag = true
          } else { this.initCurrentPage = this.currentPage + 1 }
          break
        case '4':
          if (this.userChangePageNext) {
            return
          }
          this.initCurrentPage = maxSize
          this.userChangePageNext = true
          this.userChangePagePre = false
          this.lastFlag = true
          break
        default: break
      }
      if (flag) {
        this.emitChange()
      }
    },
    // 向父级发送当前变化
    emitChange () {
      this.$nextTick(() => {
        if (this.initCurrentPage !== this.currentPage) {
          if (!(this.userChangePageSize && this.initCurrentPage <= this.currentPage)) {
            this.$emit('current-change', Number(this.initCurrentPage))
          }
        }
        if (this.userChangePageSize) {
          this.$emit('size-change', Number(this.initCurrentSize))
        }
      })
    },
    inserDefaultVal () { // 输入为空时 填充当前页码到输入框
      if (!this.initCurrentPage) {
        const currentPageN = this.pageNum.split('/')[0]
        this.initCurrentPage = currentPageN
      }
    }
  }
}
</script>
<style lang="less" scoped>
.paginationBox {
  float: right;
  line-height: 28px;
  height: 28px;
  text-align: right;
  font-size: 12px;
  margin-top: 8px;
  .left {
    float: left;
  }
  .total {
    margin-left: 5px;
    font-size: 14px;
    .totalNum {
      color: #33aa50;
    }
  }
  .pageIcon {
    cursor: pointer;
    width: 28px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 2px;
    margin-right: 2px;
  }
  .pageNum,
  .pageIcon {
    position: relative;
    top: 50%;
    -o-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .currentPage {
    width: 30px;
    text-align: center;
    height: 100%;
    background-color: #444;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 4px 0 0 4px;
  }
  .gotoPageBtn {
    width: 28px;
    height: 100%;
    cursor: pointer;
    background-color: #444;
    margin: 0;
    border-radius: 0 4px 4px 0;
  }
  .notAllowed.pageIcon {
    opacity: 1;
    filter: alpha(opacity=100);
    cursor: not-allowed;
  }
  .inputPageModule {
    margin-right: 8px;
    height: 100%;
  }
  .everyPageNum {
    height: 100%;
    margin-left: 6px;
    border-color: #d3d3d3;
    font-size: 12px;
    width: 60px;
    text-align: center;
    .dropdownDefault_value {
      font-size: 12px;
      text-align: center;
      text-indent: 0.2em;
    }
    .downArrow {
      right: 10px;
    }
  }
  .pageNum {
    margin: 0 6px;
    min-width: 24px;
  }
  .gotoPageBtn {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAAEs186GAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTBBMUZCRDRGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTBBMUZCRDVGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5MEExRkJEMkY0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5MEExRkJEM0Y0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtG0VS0AAAFzSURBVHjaYvz//z8DDLCACJPVgWARRmQZgACCc1gwpAECiAFZnfGqADCHCcpZDxW/ACIAAgjFRAwA0grSAVLEAmQoAMXuQ+UCUAyH0SCVWM0ECCAGbIJAXfOB2ABZDVg3IyMjA1TiPDaHnQldz8iEZC9M0QFkRWfDNjDCjQUqfM+AB4DUAAQQ/sDAB4CmCyAFLdxEJixqQZ4KgIasADwykUwChbACFo2JQF8vgPn6PQ5FIFAPjm6o8QJIEheQ2BOAwaMISxcFUEFBaJglQvmKQH4hclL8AFX0ARrAF5DdjpEyCQGAACM/wHEAcG4BJR4CkeUApPYjCRUCvTIBW2qFp0YshiQAqXggdsBj1wMgDgSFE1YDoUl8P1o0EQVAWQCerWGZCZoVSDUMFIuKKJECLFJAGRw5QYOiHJQWNoCiH0sYguQTgXIb0MOQBSnhgzMpUJEjuhOAYgeAhhqCkjWyITgzKyjsgLiBkuQCMoeyjI8DAABHx8HtRuJJ6AAAAABJRU5ErkJggg==');
  }
  .gotoPageBtn:hover {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAAEs186GAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTBBMUZCQ0NGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTBBMUZCQ0RGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5MDhEMTQyNEY0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5MEExRkJDQkY0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmiKzTEAAAEXSURBVHjaYvz//z8DDLBAaYgIsgxAADHCOCxwaRgACCAUdf+hHCZkDgwABBAjGh8MmBgZGRlAGGqwAVglFu2MTFABQTAPqgWkygHdNQABxIDNIqCYAl6FQPb9/5hgPkgNC0wBkFJgwA42wo39j81+NKsBAoiRgBr8AKjZAFccIAs6QD1xHqtCICfgP3YgAFcIDQJ8AGt8IYMPwNgShAV4AVRXA5ob+9FjpgGIEwiFI4gQICK4GAACjLIAx2k9YTUKaIlJAJs5OJMtcoxDAw9bdLxHDiucBhIRl9jAeZiBLMgpB0itJzPkDqD73QCHl/qhSbQAh6sUsHoZmnZgIAFLWILS935ojg/AFbnIBoJcsZ/S1EKTdAgAU7/r94UzPtYAAAAASUVORK5CYII=');
  }
  .firstIcon {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAAEhyb7BAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEZFNzBGNUVGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEZFNzBGNUZGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4RkQ5RUZGREY0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4RkQ5RUZGRUY0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqCSYNIAAAE4SURBVHjaYvj//z8DCBivClBggTJAIheYQJyzYRsYgdQHgABigAFGqDQCAAQQI8gERkZGBiTNDEwwWaBAA5R5ACCAGDAATDmQvg9iIzuAAW4O1BFYtSsABBDYepPVgf/RVQElBYDUfSAWYEGTmA+kEtBNg5sEZAsC8XssNh6AmwS07gNIE5KpIKsUQGyAACKIQDaxwPyJxeGgIKsHmc6CxcsOQGo/shgLcnhAvYwBmJDY93G5C1nRBoKKgA4PxEggWEyCKQYFqiMDsQDomf2gcAIIMJT0RQqAJoB+UFyfCV3PiJG4cKYriLoEaCRgRCkLAVsNoLY6EHIhttiFRT1JgAmLWD6JZhzAGW3QcAJF3QNiTcMZRkDDQDYpIqVXUFgFkGwQmqEglwWihSMoCARQMh0lAORakBkA0k1645e6ygMAAAAASUVORK5CYII=');
  }
  .previousIcon {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAAEhyb7BAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEZFNzBGNjZGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEZFNzBGNjdGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4RkU3MEY2NEY0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4RkU3MEY2NUY0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuR6ALoAAAEnSURBVHjaYvj//z8DCIBoJhDDeFUAQgRGAwQQAwwwwqVhACCA4PJAGQeQLNwkKFgPYwAEEAZihDFghrIAGe+BtABOFSgAKLgeIIBQnYcq2QBzDguSIMjs99g0ILu5H6eTQcYxMjKiW+UApPaD2GdC1zMCBBADPgBUrIDiJiwKYGHAyIJFEmSNA7IYsu/mA6kEbKaCgtYASJ/H5zams2EbLgBpQbyKQARQ4QcgBoVDIZr8AfTABCmeAFV8AVeIIys2BFKKMD5AgGENcUIAGs8FQFwPixEWEjSDgrAeiBWwBiW+qAVS89EjCBdgweLcfOTkTyxADsgEcg0hlLxBecKAgP4HwMBWJCrWoF7ux5FeDwANciQr+kH5HikI4AbhLCiINBScaUEYAGlGg/TvE/2JAAAAAElFTkSuQmCC');
  }
  .nextIcon {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAAEhyb7BAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTAzNEJBOURGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTAzNEJBOUVGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5MDM0QkE5QkY0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5MDM0QkE5Q0Y0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjvLqEEAAAEvSURBVHjaYvj//z8DDDCBCONVARARZBmAAIIjRrg0kCEAogECCKwOJArE81FMgoIEGAMggECqFJANA6maDzcQxXQI+IBszoGzYRsEGdBUMAAEEMz6/UD8ngEHYIHSINsF0ExwBBp7ANnNClgMAJnegGwSDDwA6lZEVw0QQNiDDk0eHtgwo7EBRpBKk9WByMYJAq38gGESGngPNPU8tiBABwbQ4CgE4glMeNwMsnIBPpMUge56AHEUpqJEoOQCrOGAz/sgeYAAY4QFJjAY9kPdUQi3ikiAzSAHJPkLUEMPEGMQCx55A2higYVYI9DQCXhjF+qi/yT4ZgHUtR+IcRE+AMqaCUDXggxyBAUDOQahuAZX/GMDB6DhgzfQWXAkxYlAjQ0MpKYBaIJTIDO8wPoButqgRn9hmDgAAAAASUVORK5CYII=');
  }
  .lastIcon {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAAEhyb7BAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTA1MTQzNjdGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTA1MTQzNjhGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5MDM0QkFBM0Y0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5MDUxNDM2NkY0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PurH3awAAAEnSURBVHjaYvj//z8DDLCACONVARARZBmAAIIjRrg0DAAEEFgdSBSIFWAGMCHJ34cxAAIIE0G1/cfQBhNENscQJjMf2QiAAGIEWW+yOhCkvPFs2IYGdPPhHoOCeqBgPZT9AKhBESbBhMORCsheZkGTTASasADdOoAAYoAFBS4AcjPIuvlAhe+xKUD3vwA0pNZjU4zu8ACoYgdkQRYcTtkPVPgB2SQHHAon4gunA8BgYEQOfWTrUEIZW/wL4AsngACDRfB+pBB/wEAiAJmB7CSQJ+8DbQY7D2roAXypFDnF4QpKBWhwgtigIJ2InhzRAQsRLhdAS7IbQK7FlQIESAgSkDPf4zLIgEhDQElfERT3pHqNYKDjMugDNClOAGr+QHIaIJQxiTEDAPo6iLIJh4s1AAAAAElFTkSuQmCC');
  }
  .notAllowed.firstIcon {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAAEhyb7BAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEZGQkE4RTNGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEZGQkE4RTRGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4RkZCQThFMUY0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4RkZCQThFMkY0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pmj5DVkAAAE+SURBVHjaYmCAAmcXZwUWINEAZNcDsSMDMgAIIDhiBCr5jyICEEAMUE1gE0CyQOzAhCS/HsYACCBMBDMMqu0/E7IgCIAF9u7Zy4hLewNAADHC3IyuCuQMILUfiANZ0CTeAykBNMM+MCEpKMCiAAzgJgGtmwCkJsB8DqTuQ6UuAAQQAz4AVCzw//9/BiaopxuwKDgPpN6jWIckOR9IJWB1E9Th/fgcXo/PbbAgaASFByFFoCAQxJVSkBMASOEBaPRsYCAWgKIJFE4AAcbIQCYAGpAA9fDCPbv3NCCnbrAngU5qwKHRABqMDmhSB7BGCnLUAilQHOTjSkBYIw4K7IEGgBKRAqleRTfIgdwwY0LjN0KjDRTPCyhxESwdgFJVIhTD0ms9vrBiIcY2tMwI8v58WDiCEh8DpQCawxlACRIA+YNnJhrBqcIAAAAASUVORK5CYII=');
  }
  .notAllowed.previousIcon {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAAEhyb7BAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTAxMTUzREFGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTAxMTUzREJGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5MDExNTNEOEY0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5MDExNTNEOUY0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Plys4wwAAAE3SURBVHjaYvj//z8DCIBoJhDD2cUZIQKjAQKIAQYY4dIwABBAcHmgzHyQLNwkKEiAMQACCAMxwhgwQ1nQTWfau2cvSJUhVv1A1e8BAgjVeaiS+2HOYUESBDlnPjYNyG7ux+lkkHGMjIzoVs2HeXTP7j2MAAHEgA8AFSugBxGypAM0DMCmsaBJCgCp9zgdDlRwH4uCA7Cg3Q+kHfC5DRTcjkA6Ea8iEAFUuAAaNxcIBSZIMSgCBdEVsaALABV+gCayApgYQIBhDXFCABrP9UC8EBgjDVhtwxU70PgPwKWGBYdGUOSAnJ0PxAJ47HiAYRAo2qHJUoEEXz5ACW2gIQZQPyswkAlYoLEAilNHJG/VQ71GNCAYa9AorscRVo7AWDtAcvRDwxEUewboBuEsKIgwVACEQfpBGAB0yoGda0xz+AAAAABJRU5ErkJggg==');
  }
  .notAllowed.nextIcon {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAAEhyb7BAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTAxMTUzRTJGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTAxRTlBNUVGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5MDExNTNFMEY0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5MDExNTNFMUY0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pvui0QYAAAE0SURBVHjaYvj//z8DCIBoJhDD2cUZIQKjAQKIAQYY4dIwABBAYHmQKBAXoJgEBf0wBkAAgVQJIOsEqXqPbCC66QeQzWncu2evI8iM+8hmAAQQsvXnGbAAkDwjiHBxdfmPRV4QaOwHdDejA5ArHUAMFjSJDUDdgeiqAQIIw6PY3ASyzgDqcAdcCpHdtB+o8D0hRSAgADW1H1mQBYcNBdBoMwTiC/iC4AHQpxdgJimgSX4AYkVQQCJbh6wIJPkA3UiYmxKBkgtw2QsQYLC4A4XRemgymMBAAoClX2SD9qOpAdleiOx+cg1CBhegXruAyyAWIn1gAMTngfEEC+lC9PBiYSAdgBLofKCh80H5DIgdyTHoATRCFuCKWgc8mokKdGwuOgC19QADqekA6F8FBjIBSD8IAwCNJZ5oy3JXogAAAABJRU5ErkJggg==');
  }
  .notAllowed.lastIcon {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAAEhyb7BAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTAxRTlBNjVGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTAxRTlBNjZGNEFCMTFFNThGRUJEQjMzNEQ5RDY0OTUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5MDFFOUE2M0Y0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5MDFFOUE2NEY0QUIxMUU1OEZFQkRCMzM0RDlENjQ5NSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtW9VsUAAAE9SURBVHjaYmBAAozOLs4OQHo/EDeyAAmHvXv2MoJkAAIIRcl/FBGAAGIAaUIWBbIbmJA472FsgADCRCBtMBUgNkybAMw8JiTFgjAtCchGAAQQM0yvkpLSwfv37j9ANx8ozsgC9RgI7AeyYfILgP5LhHGYcDgyAajhPozDgiZpCDThArJ1IBoggEAMBQY84P///2Dr7iMbjRx+6P5XgIZUATbT0N3UD1TYDw8wHIpgACV2mPC4+QA+RY3QhHIQm3UHgJKOuOL/PqFwAggweDIChQsDmQBkECjpCqD7GAg2AHEh0IkPcLgQZGk9zJ97du9pAPnNAIvaABCGpq4LUEMP4HMVCxEuN0BKsh9ALiDXIGQACoZ+bBJMJBjyAepFRmyuIuSiBdBE84CQLSxQ58IAUQGLK7EpQJMAAyXpCABxxXIFHCTTIgAAAABJRU5ErkJggg==');
  }

  ::v-deep .el-dropdown.page_dropdown {
    margin-top: 2px;
    .el-button--primary {
      height: 28px;
      padding: 6px;
      border: none;
    }
  }
  ::v-deep .el-dropdown .el-dropdown__caret-button::before {
    display: none;
  }
}
</style>
